<template>
  <span v-html="iconContent" :class="className"></span>
</template>
<script>
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'CustomIcon',
  props: {
    name: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      iconContent: ''
    };
  },
  async created() {
    await this.loadIcon();
  },
  methods: {
    async loadIcon() {
      try {
        const iconPath = await import(`@/assets/icons/${this.name}.svg?inline`);
        const response = await fetch(iconPath.default);
        this.iconContent = await response.text();
      } catch (error) {
        console.error('Error loading icon:', error);
      }
    }
  }
});
</script>